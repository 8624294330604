/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect } from 'react'
import { onPageEnter } from 'lib/tracking/pageEnter'
import { trackEvent } from 'lib/tracking/analytics-service'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import { useRouter } from 'next/router'
import { AnalyticsEventLogin } from 'lib/constants/AnalyticsEventType'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { ApiError } from 'services/ApiService'
import { parseLocale } from 'lib/constants/localization'
import UserService from 'services/UserService'
import { getReferrerScreen } from 'lib/formatting/url'
import _useTranslation from 'lib/hooks/_useTranslation'
import { getSignUpAndSignInDefaultRedirectPath } from 'lib/features/auth'
import { isDeviceMobile } from 'lib/features/deviceInfo'
import SignInFlow from 'components/feature/Authentication/SignInFlow'
import { handleRedirect } from 'lib/redirect'

function SignIn() {
    const router = useRouter()

    const { t: tCommon } = _useTranslation('common')

    const authenticatedUser = useSelector((state: AppState) => state.user)
    const authenticatedKnower = useSelector((state: AppState) => state.knower)

    const referrerScreen = getReferrerScreen(router)

    const isMobile = isDeviceMobile()

    const showWebappConversionEntryPoint = !isMobile

    useEffect(() => {
        if (authenticatedUser !== null) {
            handleRedirect(router, () => {
                router.push(
                    authenticatedKnower ? getSignUpAndSignInDefaultRedirectPath(showWebappConversionEntryPoint) : '/'
                )
            })

            return
        }

        onPageEnter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router])

    const redirectUserBasedOnType = async () => {
        await UserService.getMe()
            .then(() => {
                router.push(getSignUpAndSignInDefaultRedirectPath(showWebappConversionEntryPoint))
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    error.handleUnknownError(tCommon, 'user.getMe')
                } else {
                    throw error
                }
            })
    }

    const handleSuccess = async (type: 'email' | 'google' | 'apple') => {
        trackEvent(AnalyticsEventLogin, { value: type, referrerScreen: referrerScreen })

        handleRedirect(router, () => {
            redirectUserBasedOnType()
        })
    }

    return <SignInFlow handleSuccess={handleSuccess} />
}

export const getStaticProps: GetStaticProps = async (props) => ({
    props: {
        ...(await serverSideTranslations(parseLocale(props.locale).interfaceLanguageCode, ['common', 'auth'])),
    },
})

export default SignIn
