import { NextRouter } from 'next/router'
import { getUrlParam } from './utils'

export const handleRedirect = (router: NextRouter, fallback: () => void) => {
    const redirect = getUrlParam('redirect')

    if (!redirect || redirect === '/') {
        fallback()
        return
    }

    // We should not redirect to external URLs since it allows attackers to redirect users to malicious websites
    if (isExternalRedirect(redirect)) {
        fallback()
        return
    }

    router.push(redirect)
}

export const isExternalRedirect = (url: string) => {
    // eslint-disable-next-line no-useless-escape
    const validRedirectPattern = /^[a-zA-Z0-9\/#-]+$/
    return !url.startsWith('/') || url.includes('//') || !validRedirectPattern.test(url)
}
